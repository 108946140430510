import React from 'react';
import { Box } from '@mui/material';
import Layout from './../components/Layout/Layout';
const Policy = () => {
	return (
		<Layout>
			<Box mt="30px">
				<h1>hello</h1>
			</Box>
		</Layout>
	);
};

export default Policy;
